import Vue from 'vue'
import { Message, MessageBox } from 'element-ui'
import i18n from '@/locales'
import { printQualityPdf, printHtmlInvoice, printQualityPdfCustomPrinter } from '@/helpers/utils/print'
import { beep } from '@/helpers/utils/voiceMessages'
import printJS from 'print-js'
import router from '@/router'

const state = () => ({
  barcodeType: 0, // 0=masa/sipariş, 2=koli, 3=ürün
  currentTable: null,
  parcelList: [],
  currentOrder: null,
  productList: [],
  readedProductList: [],
  currentParcel: null,
  packageCount: 0,
  shippingType: null,
  isCargoPDF: false,
  customCargoPDFBase64: null,
  isShowCustomCargoPDF: false
})

const getters = {}

const mutations = {
  updateCurrentTable(state, payload) {
    state.currentTable = payload
  },
  updateParcelList(state, payload) {
    state.parcelList = payload
  },
  updateCurrentOrder(state, payload) {
    state.currentOrder = payload
  },
  updateProductList(state, payload) {
    state.productList = payload
  },
  addReadedProduct(state, payload) {
    let findIndex = state.readedProductList.findIndex((item) => item.orderProductID == payload.product.orderProductID)
    if (findIndex >= 0) {
      if (state.productList[payload.index].occurrencesPiece == 0) {
        state.productList[payload.index].missingPiece -= 1
      } else {
        state.productList[payload.index].occurrencesPiece -= 1
      }
      state.readedProductList[findIndex].occurrencesPiece += 1

      if (
        state.readedProductList[findIndex].piece ==
        state.readedProductList[findIndex].occurrencesPiece + state.readedProductList[findIndex].missingPiece
      ) {
        state.productList.splice(payload.index, 1)
      }
    } else {
      state.productList[payload.index].occurrencesPiece -= 1
      payload.product.occurrencesPiece = payload.readAllProduct ? payload.product.piece : 1
      payload.product.missingPiece = 0
      state.readedProductList.push(payload.product)
      //hepsi okutulduysa kontrol edilecek listesini temizler
      if (payload.product.piece == payload.product.occurrencesPiece + payload.product.missingPiece) {
        state.productList.splice(payload.index, 1)
      }
    }
  },
  addMissingProduct(state, payload) {
    let findIndexProductList = state.productList.findIndex((item) => item.barcode == payload.barcode)
    let findIndex = state.readedProductList.findIndex((item) => item.barcode == payload.barcode)
    if (findIndex >= 0) {
      state.readedProductList[findIndex].missingPiece += 1

      if (state.productList[findIndexProductList].missingPiece == 0) {
        state.productList[findIndexProductList].occurrencesPiece -= 1
      } else {
        state.productList[findIndexProductList].missingPiece -= 1
      }

      if (state.productList[findIndexProductList].missingPiece == 0 && state.productList[findIndexProductList].occurrencesPiece == 0)
        //kontrol bekleyen ürünler listesinde eksik adet ve toplanan 0 olursa ürün kontrolü bitmiştir, o yüzden listeden kaldır
        state.productList.splice(findIndexProductList, 1)
    } else {
      payload.missingPiece = 0
      state.productList[findIndexProductList].missingPiece -= 1
      payload.missingPiece += 1
      payload.occurrencesPiece = 0
      state.readedProductList.push(payload)
      if (payload.piece == payload.occurrencesPiece + payload.missingPiece) state.productList.splice(findIndexProductList, 1)
    }
  },
  updateReadedProductList(state, payload) {
    state.readedProductList = payload
  },
  setCurrentParcel(state, payload) {
    state.currentParcel = payload
  },
  changeBarcodeType(state, payload) {
    state.barcodeType = payload
  }
}

const actions = {
  async completeQualityControl({ state, commit, dispatch }) {
    if (state.productList.length == 0) {
      let productsToSend = []
      let totalMissingPiece = 0
      state.readedProductList.map((item) => {
        let productModel = {
          productID: item.productID,
          orderProductID: item.orderProductID,
          piece: item.occurrencesPiece,
          missingPiece: item.missingPiece
        }
        totalMissingPiece += item.missingPiece
        productsToSend.push(productModel)
      })

      //eksik ürün sayısı 0'dan fazla ve eksik koli barkodu girme ayarı açıksa
      if (totalMissingPiece > 0 && this.getters['user/getSettings'].orderCombineSettings.requestedParcelActive) {
        await this.dispatch('orderAssembly/openAssignMissingBoxPopup', true)
        return
      }
      try {
        const response = await Vue.prototype.$axios.post(`/api/quality-controls/orders/${state.currentOrder.id}/completed`, {
          desi: state.currentOrder.totalDesi,
          giftPackage: state.currentOrder.isGiftPackage,
          boxID: state.currentParcel?.id,
          products: productsToSend,
          packageCount: state.packageCount !== 0 ? state.packageCount : 0,
          shippingType: state.shippingType !== null ? state.shippingType : null
        })

        Message({ type: 'success', message: i18n.t('SiparisKaliteKontrolYapildi') })
        commit('updateCurrentOrder', null)
        commit('setCurrentParcel', null)

        if (this.getters['user/getSettings'].kaliteKontrolAyar.koliGoster) {
          await dispatch('getUserTable')
        } else {
          commit('changeBarcodeType', 0)
        }
        if (this.getters['user/getUser'].customPrinter) {
          printQualityPdfCustomPrinter(
            { ...response.data.model, settings: { showCustomCargoPdf: this.getters['user/getSettings'].kaliteKontrolAyar.showCustomCargoPdf } },
            this.getters['user/getUser'].id
          )
        } else {
          let isCargoPDF = this.getters['user/getSettings'].kaliteKontrolAyar.showCustomCargoPdf && response.data.model.customCargoPDF !== null
          let isPrintOrderPdf = this.getters['user/getSettings'].kaliteKontrolAyar.printOrderPdf
          return new Promise((resolve) => {
            if (response.data.model.cargoHtml != null) {
              printHtmlInvoice(response.data.model.cargoHtml)
            }
            if (response.data.model.orderHtml != null && isPrintOrderPdf) {
              printHtmlInvoice(response.data.model.orderHtml)
            }

            if (this.getters['user/getSettings'].kaliteKontrolAyar.printOrderPdf && response.data.model.invoicePDF !== null) {
              printJS({
                printable: response.data.model.invoicePDF,
                type: 'pdf',
                base64: true,
                onPrintDialogClose: function (event) {
                  if (event === true && isCargoPDF) {
                    state.isShowCustomCargoPDF = true //iptal butonu
                    state.customCargoPDFBase64 = response.data.model.customCargoPDF
                    router.push({ name: 'KaliteKontrolKargoPDF' })
                  } else {
                    console.log('isCargoPDF', isCargoPDF)
                    if (isCargoPDF) {
                      console.log('deneme3')
                      state.isShowCustomCargoPDF = true //iptal butonu
                      state.customCargoPDFBase64 = response.data.model.customCargoPDF
                      router.push({ name: 'KaliteKontrolKargoPDF' })
                    }
                  }
                }
              })
            } else {
              console.log('else')
              if (isCargoPDF) {
                console.log('isCargoPDF else')
                printJS({
                  printable: response.data.model.customCargoPDF,
                  type: 'pdf',
                  base64: true
                })
              }
            }
            resolve()
          })
        }
      } catch (error) {
        console.log(error)
        // dispatch('leaveOrder')
        // console.log("%cBeklenmeyen Bir Hata Oluştu. Kod:"+error.response.status,"color: white; background:red; border-radius:6px; padding:3px; font-size: 13px")
        if (error.response.data.Key == 'ORDER_APPOINTMENT_MISSING_PARCEL') {
          //sipariş eksik ürün kolisine aktarıldıysa
          commit('updateCurrentOrder', null)
          commit('setCurrentParcel', null)
          await dispatch('getUserTable')
        }
      }
    } else {
      Message({ type: 'warning', message: i18n.t('KaliteKontrolTamamlanmamis') })
    }
  },
  // MASA ATA
  async assignTable({ commit }) {
    try {
      const response = await Vue.prototype.$axios.post(`/api/quality-controls/tables/${this.state.app.barcode}/assign`)
      commit('updateCurrentTable', response.data.model.table)
      commit('updateParcelList', response.data.model.table.parcels)
      commit('changeBarcodeType', 2)
    } catch (error) {
      //HATA
    }
  },
  // MASAYI BIRAK
  async leaveTable({ commit }) {
    try {
      const response = await Vue.prototype.$axios.post('/api/StoreAgent/LeaveTable')
      commit('updateCurrentTable', null)
      commit('updateParcelList', [])
      commit('changeBarcodeType', 0)
    } catch (error) {
      //HATA
    }
  },
  //KULLANICININ ÜSTÜNDEKİ MASAYI ÇEK
  async getUserTable({ commit }) {
    try {
      const response = await Vue.prototype.$axios.get('/api/StoreAgent/GetAgentTable')
      commit('updateCurrentTable', response.data.model.table)
      commit('updateParcelList', response.data.model.table.parcels)
      commit('changeBarcodeType', 2)
    } catch (error) {
      //HATA
    }
  },
  //KUTUDAKİ SİPARİŞİ ÇEK
  async getOrder({ commit, state }, parcel) {
    try {
      const response = await Vue.prototype.$axios.get('/api/quality-controls/orders', {
        params: {
          barcode: parcel.barcode,
          boxID: parcel.id,
          setNo: parcel.setNo
        }
      })
      commit('updateCurrentOrder', response.data.model.order)
      if (parcel.id) commit('setCurrentParcel', parcel)
      commit('updateProductList', state.currentOrder.products)
      commit('updateReadedProductList', [])
      commit('changeBarcodeType', 3)
    } catch (error) {
      //HATA
    }
  },
  // ÜRÜN KONTROL ET
  async findProduct({ state }) {
    if (this.getters['user/getSettings'].urunToplamaAyar.cokluBarkod)
      return state.productList.findIndex((item) => item.barcode == this.state.app.barcode || item.barcodes.includes(this.state.app.barcode))
    else return state.productList.findIndex((item) => item.barcode == this.state.app.barcode)
  },
  async readProduct({ state, commit, dispatch }) {
    let findProductIndex = await dispatch('findProduct')
    if (findProductIndex >= 0) {
      setTimeout(() => {
        beep('yes')
      }, 50)
      commit('addReadedProduct', { product: { ...state.productList[findProductIndex] }, index: findProductIndex })
    } else {
      beep('no')
      Message({ type: 'warning', message: i18n.t('UrunKontrolListesindeBulunamadi') })
    }
  },
  // KUTU OKUT
  readParcel({ state, dispatch }) {
    let findParcel = state.parcelList.findIndex((item) => item.barcode == this.state.app.barcode)
    if (findParcel >= 0) {
      dispatch('getOrder', state.parcelList[findParcel])
      setTimeout(() => {
        beep('yes')
      }, 50)
    } else {
      Message({ type: 'warning', message: i18n.t('KoliBulunamadi') })
      beep('no')
    }
  },
  // SİPARİŞİ BIRAK
  leaveOrder({ commit }) {
    commit('updateCurrentOrder', null)
    commit('setCurrentParcel', null)
    commit('updateReadedProductList', [])
    commit('changeBarcodeType', 0)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
