import axios from 'axios'
import Vue from 'vue'
import { Loading, Message } from 'element-ui'
import i18n from '@/locales'
import store from '@/store'
// import router from "@/router"

let exceptingKeys = [
  //sayfaya özel hata mesajı çıkarmak için eklenen istisna keyleri
  'SHELF_NOT_FOUND',
  'SiparisinizYok',
  'ArabaBulunamadi',
  // 'KullanimdaArabaBulunamadi',
  // 'ArabanizaAitBirSetBulunmaktadir',
  'USER_SET_NOT_FOUND',
  'USER_CAR_ALERT_NOT_FOUND',
  'KullaniciUzerindeMasaYok',
  'ORDER_IS_NOT_FOUND',
  'OTPBosOlamaz',
  'ArabaninMasasiBulunmamakta',
  'ORDER_PRODUCT_REJECT_AND_CHANGED_STORE_INFORMATION'
]

let loadingInstance

// 'https://wms-api-dev.ticimax.cloud'
// 'https://wmstestapi.ticimax.cloud'
// 'https://wmsbetaapi.ticimax.cloud'
// 'https://wmsapi.ticimax.cloud'

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})
axiosInstance.interceptors.request.use((config) => {
  //loader open
  if (store.state.app.axios.showLoading) loadingInstance = Loading.service()

  if (Vue.$cookies.get('customApiURL')) config.baseURL = Vue.$cookies.get('customApiURL')
  config.headers['usertoken'] = Vue.$cookies.get('userToken') ?? null
  return config
})
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.headers.checkuser == 'true' && JSON.parse(Vue.$cookies.get('userType')) != 999) {
      //kullanıcı bilgileri güncellenmiş
      store.dispatch('user/getUserInformation')
    }
    if (loadingInstance != undefined) loadingInstance.close()

    if (response.data.isError) {
      if (response.data.errorMessage == 'NotLogin' && !window.location.pathname.includes('/login')) {
        Vue.$cookies.remove('userToken')
        window.location = '/login'
      }
      Message.error(i18n.t(response.data.errorMessage))
      return response
    }
    return response
  },
  (error) => {
    if (loadingInstance != undefined) loadingInstance.close()
    //503 kodunun server kontrolü için en üstte olması gerekiyor
    if (error.response.status === 503) {
      store.dispatch('app/setServerStatusAction', false)
      return Promise.reject(error)
    }
    if (error.response.status === 0) {
      Message.error(i18n.t('BeklenmeyenBirHataOlustu'))
      return Promise.reject(error)
    }

    if (error.response.data.Key && !exceptingKeys.includes(error.response.data.Key)) {
      Message.error(i18n.t(error.response.data.Key, { ...error.response.data.Params }))
    }
    if (error.response.data.isError) {
      Message.error(i18n.t(error.response.data.errorMessage))
    }
    if (error.response.data.title) {
      Message.error(error.response.data.title)
    }
    return Promise.reject(error)
  }
)
