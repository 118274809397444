import Vue from 'vue'

const state = () => ({
  isOpen:false
})

const getters = {
  
}

const mutations = {
  
}

const actions = {
  async signIn({}, information) {
   window.console.log("as")
    try {
      const response = await Vue.prototype.$axios.post('/api/Member/Login', {
        domainName: information.domain.toLocaleLowerCase('tr-TR').trim(),
        username: information.username.trim(),
        password: information.password,
        captchaCode: information.captcha || null,
        otp: information.otpCode || null
      })

      if (response.data.isError) {
        throw response
      }

      localStorage.removeItem('wmsPrint') //önceden kalan printleri temizleme ilk girişte

      Vue.$cookies.set('userToken', response.data.model.userToken)

      await this.dispatch('user/setUserInformation', response.data.model.user)
      
      await this.dispatch('user/setUserAction')
      
      if (response.data.model.user.type == 1) {
        // user dağıtıcı değil ise
        window.location = '/'
      } else if (response.data.model.user.type == 2) {
        // user dağıtıcı ise
        window.location = "/courier/orders"
      } else if (response.data.model.user.type === 999) {
        window.location = '/setup'
      } else {
        // kullanıcı tipi bilinmiyor.
        throw new Error("GecersizKullaniciTipi");
      }
    } catch (error) {
      throw error
    }
  },
  async signOut() {
    try {
      await Vue.prototype.$axios.get('/api/Member/Logout')
    } catch (error) {
      console.log("%cBir Hata Oluştu.", "color: white; background:red; border-radius:6px; padding:3px; font-size: 13px");
    } finally {
      Vue.$cookies.remove('userToken')
      Vue.$cookies.remove('storeID')
      Vue.$cookies.remove('warehouseID')
      Vue.$cookies.remove('userType')
      Vue.$cookies.remove('tcmxwmsuser')
      localStorage.removeItem('settings')
      localStorage.removeItem('permissions')
      window.location = '/login'
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}